@media (max-width: 1680px) and (min-width: 1280px) {
  .inner {
    width: 1170px;
  }
  :root {
    --prodGap: 15px;
    --prodGapSlider: 15px;
    --rowGap: 72px;
  }
  #main-bar .mainmenu,
  #tree {
    gap: 16px;
  }
  .promo-banners-wrapper {
    margin-top: -10px;
  }
  .detail-main-wrapper {
    gap: 72px;
  }
  .mainpage-products-list {
    gap: 30px 20px;
  }
  .mainpage-products-list .product .product-price:after {
    bottom: -8px;
  }
  .mainpage-products-list .product:first-child .product-price:after {
    bottom: -24px;
  }
}
@media (max-width: 1279px) {
  :root {
    --rowGap: 25px;
    --fontSize: 13px;
    --lineHeight: 1.4;
  }
  .detail-omnibus {
    align-self: flex-start;
    margin-top: 0;
    font-size: 11px;
  }
  .footer-bottom-bar {
    padding: 30px 0 100px;
  }
  #container {
    overflow: clip;
  }
  .detail-omnibus {
    text-align: left;
  }
  main {
    padding-bottom: var(--rowGap) !important;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--rowGap) 0;
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  body:has(.left-side-cookies) .privacy-box-trigger {
    bottom: 15px;
    width: 35px;
    height: 35px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.right-side-cookies {
    right: 15px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.left-side-cookies {
    left: 15px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
  input[type=text],
  input[type=password],
  input[type=email],
  select,
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    height: 80px;
    margin-top: -40px !important;
  }
  #detail-images .swiper-button-prev {
    left: -45px;
  }
  #detail-images .swiper-button-next {
    right: -45px;
  }
  .headline {
    font-size: 20px !important;
    margin-bottom: 16px;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  #search-engine {
    display: none;
  }
  .newsletter-bg {
    background-image: none !important;
  }
  .newsletter-input {
    width: 100%;
  }
  .footer-content {
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-item {
    width: 100% !important;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
  }
  .filter-trigger,
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
    height: 40px;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
    font-size: 13px;
    gap: 5px;
    padding: 0 25px;
    min-width: 0;
    font-weight: bold;
    color: #182029;
    border-color: #182029;
  }
  .filter-trigger:before,
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button:before {
    display: none;
  }
  .filter-trigger {
    font-size: 0px;
    gap: 4px;
    font-weight: 600;
    padding-left: 16px;
  }
  .filter-trigger:before {
    font-family: icomoon;
    line-height: 1;
    display: block;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    color: inherit;
    font-size: var(--iconSize);
    content: "\e916";
    font-weight: 600;
  }
  .filter-trigger:after {
    content: 'Filtry i sortowanie';
    font-size: 13px;
  }
  .dropdown-picker[data-dropdown-type="sort"] {
    display: none;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]):before {
    display: block;
    color: #182029;
    font-weight: normal;
    left: 17px;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
    min-width: 160px;
    border-color: #DDDDDD;
    font-weight: normal;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button:after {
    color: #182029;
    font-weight: normal;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-listing label {
    padding: 5px 25px;
  }
  #filter {
    display: none;
  }
  .product-images-mini {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .swiper-slide {
    width: 10px;
    height: 10px;
    background-color: #DDDDDD;
    border-radius: 50%;
    margin: 0 5px;
  }
  .product-images-mini img {
    display: none !important;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  hr {
    margin: 20px 0;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 300ms ease 0s;
    inset: 0px;
    z-index: 1001;
    pointer-events: none;
    opacity: 0;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
  }
  :root {
    --prodGap: 20px;
    --prodGapSlider: 15px;
    --prodGapVertical: 30px;
    --rowGap: 48px;
    --iconSize: 20px;
  }
  .top-bar {
    padding: 4px;
    font-size: 12px;
  }
  .shop-header-container {
    padding: 8px 0;
  }
  #container {
    overflow: clip;
  }
  .shop-header {
    padding-bottom: 0;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  }
  .mainmenu {
    display: none;
  }
  .tree-canvas {
    display: block;
  }
  .logo {
    height: 38px;
  }
  .header-tools-wrapper {
    gap: 24px;
  }
  #cart-box,
  #wishlist-box,
  .log-button,
  .search-engine-trigger,
  .tree-trigger {
    padding-top: 28px;
  }
  #cart-box:before,
  #wishlist-box:before,
  .log-button:before,
  .search-engine-trigger:before,
  .tree-trigger:before {
    font-size: 22px;
  }
  #cart-box em,
  #wishlist-box em,
  .log-button em,
  .search-engine-trigger em,
  .tree-trigger em {
    letter-spacing: 0.1em;
    font-size: 9px;
    font-weight: 600;
    display: block;
    color: #182029;
  }
  .search-engine-trigger:before {
    content: "\e919";
  }
  .hamburger-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 22px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    justify-content: center;
    align-items: center;
  }
  .hamburger-icon span {
    display: block;
    width: 80%;
    height: 1px;
    border-radius: 50%;
    background-color: #182029;
  }
  #cart-box i {
    right: 4px !important;
  }
  #wishlist-box:after {
    right: 14px;
  }
  .product:after {
    display: none !important;
  }
  .product {
    gap: 2px;
    padding-bottom: 41px;
  }
  .product-price {
    gap: 10px;
    font-size: 14px;
  }
  .product-price del {
    font-size: 13px;
    position: relative;
    top: 1px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }
  .product-image {
    position: static;
  }
  .product-hover {
    opacity: 1;
    visibility: visible;
    background: none;
  }
  .product-hover a.product-info {
    display: none;
  }
  a.product-name:after {
    content: '';
    position: absolute;
    inset: 0;
  }
  .product-hover-buttons {
    top: initial;
    bottom: 1px;
    left: 0;
    transform: none;
    width: 100%;
    gap: 4px;
    z-index: 0;
  }
  .product-hover-buttons button {
    border: 1px solid #182029;
    color: #182029;
    height: 32px;
    width: 32px;
    z-index: 3;
  }
  .product-hover-buttons button:before {
    color: inherit;
    font-size: 20px;
  }
  .product-hover-buttons .product-add {
    flex: 1;
    display: flex;
    gap: 6px;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
    letter-spacing: 0.01em;
  }
  .product-hover-buttons .product-add:after {
    content: 'Kup produkt';
    white-space: nowrap;
    font-size: 13px;
  }
  .product-hover-buttons .product-add.disabled {
    display: flex !important;
    border: 1px solid #9d9d9d;
    color: #9d9d9d;
  }
  .promo-banners-wrapper {
    margin-top: -8px;
    gap: 16px;
  }
  .promo-banner {
    padding: 16px;
  }
  .promo-banner figure {
    width: 36px;
    margin-bottom: 6px;
  }
  .slider-header {
    margin-bottom: 16px;
    gap: 24px;
  }
  .slider-header .headline {
    margin-bottom: 0px !important;
  }
  .fancy-button {
    margin: 16px auto 0 auto;
  }
  .mainpage-products-list {
    gap: 30px 20px;
  }
  .mainpage-products-list .product-price:after {
    display: none !important;
  }
  .mainpage-products-list .product:first-child .product-image {
    margin-bottom: 12px;
  }
  .mainpage-products-list .product:first-child .product-name {
    font-size: 18px;
    margin-bottom: 4px;
  }
  .mainpage-products-list .product:first-child .product-price {
    font-size: 18px;
  }
  .mainpage-products-list .product:first-child .product-price del {
    font-size: 16px;
  }
  .mainpage-products-list .product:first-child .product-hover-buttons {
    bottom: 104px;
  }
  .mainpage-greeting .headline {
    margin-bottom: 14px;
  }
  .footer-content {
    flex-direction: column;
  }
  .footer-item > strong {
    margin-bottom: 6px;
  }
  .footer-item.submenu ul {
    columns: 1;
  }
  .footer-item.contact .contact-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .footer-item.contact .contact-wrapper a.phone {
    padding-bottom: 5px;
  }
  .footer-bottom-bar {
    padding-block: 20px 67px;
    flex-direction: column;
    gap: 16px;
  }
  #breadcrumb {
    padding: 10px 0;
    margin-bottom: -24px;
  }
  .text-expand-wrapper:has(.expandable) {
    padding-bottom: 48px;
  }
  .navigation-wrapper {
    gap: 16px;
  }
  .listing-wrapper {
    gap: 20px;
  }
  #pills-container:not(:empty) {
    margin-bottom: 8px !important;
  }
  .category-headline {
    margin-bottom: 0 !important;
  }
  body[data-canvas-filter]:has(#filter .gs-filtr-container) .filter-trigger {
    display: flex;
  }
  .detail-main-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  #detail-images {
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: auto;
    left: auto;
    margin: 0 auto;
    position: relative;
    top: auto;
    width: 100%;
  }
  #detail-images .swiper-button-prev {
    left: 10px;
    height: 48px;
  }
  #detail-images .swiper-button-next {
    right: 10px;
    height: 48px;
  }
  .product-images-mini {
    order: initial;
    height: auto;
    width: 100%;
  }
  .detail-headline {
    margin-bottom: 0 !important;
    text-align: center;
  }
  .detail-products-wrapper strong.headline {
    margin-bottom: 16px;
  }
  #detail-info {
    max-width: none;
  }
  #detail-info .detail-pricing,
  #detail-info .detail-omnibus {
    align-self: center;
    margin-bottom: 10px;
  }
  .product-images-mini .size-custom {
    padding: 0 !important;
  }
  .detail-options {
    padding-top: 10px;
  }
  .detail-adding {
    margin-bottom: 20px;
  }
  #detail-description,
  #detail-traits {
    margin-top: 28px;
  }
  .detail-ask-button,
  .detail-wishlist {
    padding-left: 32px;
  }
  #detail-traits .trait {
    gap: 24px;
  }
  #detail-traits .trait b {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
  #detail-traits .trait .trait-desc {
    flex: 1;
  }
  .detail-comments-header {
    margin-bottom: 16px;
  }
  .detail-comments-header .headline {
    margin-bottom: 0;
  }
  .comments-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .comment > cite {
    padding: 20px;
    margin-bottom: 12px;
  }
  .comment-response {
    margin-block: 12px 6px;
    padding: 20px;
  }
  .under-comment .added-date {
    padding-left: 12px;
    margin-left: 0;
  }
  .under-comment .purchase-confirmed {
    gap: 6px;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
@media (max-width: 1100px) {
  :root {
    --rowGap: 32px;
  }
  .promo-banners-wrapper {
    margin-top: 0;
  }
  .pagination {
    margin-top: 4px;
    gap: 24px;
  }
  #detail-description,
  #detail-traits {
    margin-top: 12px;
  }
  .text-expand-wrapper {
    margin-top: 10px;
  }
  .under-comment {
    padding: 0;
    font-size: 12px;
    gap: 13px;
  }
  .under-comment .added-date {
    padding-left: 13px;
  }
  .under-comment .purchase-confirmed {
    gap: 8px;
  }
  .under-comment .purchase-confirmed:before {
    font-size: 18px;
  }
  .comment-response {
    margin-left: 16px;
  }
  .header-tools-wrapper {
    gap: 18px;
  }
  .promo-banner {
    padding: 12px;
  }
  .promo-banner figure {
    width: 32px;
    margin-bottom: 4px;
  }
  .promo-banner b {
    font-size: 13px;
    margin-bottom: 2px;
  }
  .promo-banner p {
    font-size: 12px;
  }
  .mainpage-products-list {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 12px;
  }
  .mainpage-products-list .product:first-child {
    grid-column: 1 / 4 !important;
    grid-row: 1 / 2 !important;
    justify-self: center;
  }
  .mainpage-products-list .product:first-child .product-hover-buttons {
    bottom: 0px;
  }
  .mainpage-products-list .product:nth-child(5) {
    display: none;
  }
  .product .product-badges span {
    padding: 2px 4px;
    font-size: 10px;
  }
  .mainpage-products-list .product:first-child .product-badges span {
    padding: 4px 8px;
    font-size: 12px;
  }
  .buy-button,
  .availability-modal-trigger {
    height: 64px;
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .filter-trigger {
    padding: 0 20px;
    justify-content: flex-start;
    gap: 4px;
    width: fit-content;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    width: 30px;
    height: 30px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .navigation-wrapper {
    padding-top: 0;
    border-top: 0;
  }
  .availability-modal-wrapper .wrapper {
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .detail-amount {
    width: 100%;
    margin: 0 0 10px;
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  #pills-container:not(:empty) {
    margin-bottom: 20px;
  }
  #detail-traits .trait {
    flex-direction: column;
    gap: 6px;
    padding: 12px 0;
  }
  #detail-traits .trait b {
    width: 100%;
    justify-content: flex-start;
  }
  #detail-traits .trait .trait-desc {
    width: 100%;
  }
  .detail-comments-header {
    flex-wrap: wrap;
  }
  .newsletter-input:after {
    left: calc(50% + 40px);
  }
  .under-comment {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: minmax(0, 55fr) minmax(0, 45fr);
    padding: 0;
    gap: 6px;
    margin-top: -4px;
  }
  .under-comment .author {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    align-self: end;
    position: relative;
    top: -3px;
  }
  .under-comment .stars-rating {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    margin-left: auto;
  }
  .under-comment .added-date {
    margin: 0;
    padding: 0;
    border-left: none;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    align-self: start;
  }
  .under-comment .purchase-confirmed {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    white-space: nowrap;
  }
  .under-comment .purchase-confirmed:before {
    display: none;
  }
  .product .product-badges span {
    font-size: 9px;
  }
  .product .product-badges span.promo-badge {
    position: static;
    order: 1;
  }
  .mainpage-products-list .product:first-child .product-badges span {
    font-size: 12px;
  }
  .mainpage-products-list .product:first-child .product-badges span.promo-badge {
    position: absolute;
  }
  .mainpage-products-list {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .mainpage-products-list .product:first-child {
    grid-column: 1 / 3 !important;
  }
  .mainpage-products-list .product:nth-child(5) {
    display: grid;
    grid-auto-rows: max-content;
  }
  body[data-type="detail"] .headline {
    font-size: 18px !important;
  }
  .text-expand-wrapper:has(.expandable) {
    padding-bottom: 40px;
  }
  .buy-button,
  .availability-modal-trigger {
    height: 52px;
  }
}
@media (max-width: 600px) and (max-width: 532px) {
  .shop-header-container {
    flex-direction: column;
    gap: 10px;
  }
  .logo {
    height: 32px;
  }
  .header-tools-wrapper {
    width: 100%;
    justify-content: space-evenly;
  }
}
@media (max-width: 600px) and (max-width: 390px) {
  .dropdown-picker[data-dropdown-type="pageSize"],
  .filter-trigger {
    width: 100%;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button,
  .filter-trigger .dropdown-button {
    width: 100%;
  }
}
@media (max-width: 600px) and (max-width: 374px) {
  :root {
    --prodGapVertical: 20px;
  }
  .product .product-badges span {
    font-size: 12px;
    padding: 4px 8px;
  }
  .product .product-badges span.promo-badge {
    position: absolute;
  }
  .mainpage-products-list {
    display: flex;
    flex-direction: column;
    gap: var(--prodGapVertical);
  }
  .mainpage-products-list .product:first-child {
    grid-column: 1 / 2 !important;
  }
  .mainpage-products-list .product:first-child .product-image {
    margin-bottom: 8px;
  }
  .mainpage-products-list .product:first-child .product-name,
  .mainpage-products-list .product:first-child .product-price {
    font-size: 14px;
  }
  .mainpage-products-list .product:first-child .product-name del,
  .mainpage-products-list .product:first-child .product-price del {
    font-size: 13px;
  }
}
