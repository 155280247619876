// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    :root {
        --rowGap: 32px;
    }

    .promo-banners-wrapper {
        margin-top: 0;
    }

    .pagination {
        margin-top: 4px;
        gap: 24px;
    }

    #detail-description, #detail-traits {
        margin-top: 12px;
    }

    .text-expand-wrapper {
        margin-top: 10px;
    }

    .under-comment {
        padding: 0;
        font-size: 12px;
        gap: 13px;

        .added-date {
            padding-left: 13px;
        }

        .purchase-confirmed {
            gap: 8px;

            &:before {
                font-size: 18px;
            }
        }
    }
    
    .comment-response {
        margin-left: 16px;
    }

    .header-tools-wrapper {
        gap: 18px;
    }

    .promo-banner {
        padding: 12px;
       

        figure {
            width: 32px;
            margin-bottom: 4px;
        }

        b {
            font-size: 13px;
            margin-bottom: 2px;
        }

        p {
            font-size: 12px;
        }
    }

    .mainpage-products-list {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
        border-bottom: 1px solid @border-color;
        padding-bottom: 12px;

        .product:first-child {
            grid-column: 1 / 4 !important;
            grid-row: 1 / 2 !important;
            justify-self: center;

            .product-hover-buttons {
                bottom: 0px;
            }
        }

        .product:nth-child(5) {
            display: none;
        }
    }

    .product .product-badges {
        span {
            padding: 2px 4px;
            font-size: 10px;
        }
    }
    .mainpage-products-list .product:first-child .product-badges {
        span {
            padding: 4px 8px;
            font-size: 12px;
        }
    }

    .buy-button,
    .availability-modal-trigger {
        height: 64px;
    }
}