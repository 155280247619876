// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 600px) {

    .detail-share-links-wrapper span {
        width: 100%;
    }

    .stars-rating.average-rating {
        top: -2px;
    }

    .gs-tabs {

        nav {
            flex-direction: column;

            button {
                width: 100%;
            }
        }
    }

    .user-custom-content {

        iframe {
            max-width: 100%;
        }
    }

    .filter-trigger {
        padding: 0 20px;
        justify-content: flex-start;
        gap: 4px;
        width: fit-content;
    }

    .privacy-box {
        padding: 10px;
        gap: 10px;
    }

    .privacy-box__header {
        gap: 10px;

        img {
            height: 20px;
        }

        strong {
            font-size: 14px;
        }
    }

    .privacy-box__actions {
        flex-wrap: wrap;

        button {
            min-height: 35px;
        }
    }

    .privacy-box__actions button.privacy-accept-all {
        width: 100%;
        order: -1;
        flex: 0 0 auto;
        min-height: 45px;
    }

    .pagination {
        gap: 4px;

        a {
            width: 30px;
            height: 30px;
        }
    }

    #breadcrumb {
        display: flex;
        margin-left: -10px;
        width: ~"calc(100% + 20px)";
        justify-content: flex-start;
        position: relative;

        .breadcrumb-end {
            display: block;
            position: absolute;
            top: 0;
            top: 0;
            height: 100%;
            width: 20px;
            pointer-events: none;

            &:nth-of-type(1) {
                left: -7px;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }

            &:nth-of-type(2) {
                right: -7px;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }
        }

        ul {
            flex-wrap: nowrap;
            overflow-y: auto;
            justify-content: flex-start;
            flex-direction: row-reverse;
            padding-bottom: 5px;
        }

        li {
            white-space: nowrap;

            &:last-of-type {
                padding-right: 10px;
            }

            &:first-of-type {
                padding-left: 10px;
            }

            &:nth-of-type(1) {
                order: 10;
            }

            &:nth-of-type(2) {
                order: 9;
            }

            &:nth-of-type(3) {
                order: 8;
            }

            &:nth-of-type(4) {
                order: 7;
            }

            &:nth-of-type(5) {
                order: 6;
            }
        }
    }

    #detail-images .swiper-button-prev {
        left: 0;
    }

    #detail-images .swiper-button-next {
        right: 0;
    }

    #gsGalleryContainer > a {
        width: 100%;
    }

    .navigation-wrapper {
        padding-top: 0;
        border-top: 0;
    }

    .availability-modal-wrapper .wrapper {
        justify-content: center;
        gap: 10px;

        > p {
            text-align: center;
        }

        > .availability-product {
            justify-content: center;
        }
    }

    .availability-modal-wrapper .availability-product {
        gap: 10px;
        padding: 10px 0;
    }

    .availability-modal-wrapper .input-wrapper {
        flex-wrap: wrap;

        > * {
            width: 100%;
        }
    }

    .product-images {
        width: 100%;
    }

    .product-images .size-full {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }

    .detail-amount {
        width: 100%;
        margin: 0 0 10px;
    }

    .pills-group:before,
    .remove-all-filters {
        font-size: 11px;
    }

    .single-pill {
        font-size: 11px;
        padding: 2px 5px;
    }

    #pills-container:not(:empty) {
        margin-bottom: 20px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    @media (max-width: 532px) {

        .shop-header-container {
            flex-direction: column;
            gap: 10px;
        }

        .logo {
            height: 32px;
        }

        .header-tools-wrapper {
            width: 100%;
            justify-content: space-evenly;
        }
    }

    @media (max-width: 390px) {
        .dropdown-picker[data-dropdown-type="pageSize"],
        .filter-trigger {
            width: 100%;

            .dropdown-button {
                width: 100%;
            }
        }
    }

    @media (max-width: 374px) {

        :root {
            --prodGapVertical: 20px;
        }

        .product .product-badges span {
            font-size: 12px;
            padding: 4px 8px;

            &.promo-badge {
                position: absolute;
            }
        }

        .mainpage-products-list {
            display: flex;
            flex-direction: column;
            gap: var(--prodGapVertical);

            .product:first-child {
                grid-column: 1 / 2 !important;

                .product-image {
                    margin-bottom: 8px;
                }

                .product-name,
                .product-price {
                    font-size: 14px;

                    del {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    // ALL 600 //////////////////////////////////////////////////////////////

    #detail-traits .trait {
        flex-direction: column;
        gap: 6px;
        padding: 12px 0;

        b {
            width: 100%;
            justify-content: flex-start;
        }

        .trait-desc {
            width: 100%;
        }
    }

    .detail-comments-header {
        flex-wrap: wrap;
    }

    .newsletter-input:after {
        left: calc(50% + 40px);
    }

    .under-comment {
        .grid();
        grid-template-columns: minmax(0, 55fr) minmax(0, 45fr);
        padding: 0;
        gap: 6px;
        margin-top: -4px;

        .author {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
            align-self: end;
            position: relative;
            top: -3px;
        }

        .stars-rating {
            grid-row: 1 / 2;
            grid-column: 2 / 3;
            margin-left: auto;
        }

        .added-date {
            margin: 0;
            padding: 0;
            border-left: none;
            grid-row: 2 / 3;
            grid-column: 1 / 2;
            align-self: start;
        }

        .purchase-confirmed {
            grid-row: 2 / 3;
            grid-column: 2 / 3;
            white-space: nowrap;

            &:before {
                display: none;
            }
        }
    }

    .product .product-badges span {
        font-size: 9px;

        &.promo-badge {
            position: static;
            order: 1;
        }
    }

    .mainpage-products-list .product:first-child .product-badges span {
        font-size: 12px;

        &.promo-badge {
            position: absolute;
        }
    }

    .mainpage-products-list {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;

        .product:first-child {
            grid-column: 1 / 3 !important;
        }

        .product:nth-child(5) {
            .grid();
        }
    }

    body[data-type="detail"] .headline {
        font-size: 18px !important;
    }

    .text-expand-wrapper {

        &:has(.expandable) {
            padding-bottom: 40px;
        }
    }

    .buy-button,
    .availability-modal-trigger {
        height: 52px;
    }
}