// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    :root {
        --prodGap: 15px;
        --prodGapSlider: 15px;
        --rowGap: 72px;
    }

    #main-bar
    .mainmenu,
    #tree {
        gap: 16px;
    }

    .promo-banners-wrapper {
        margin-top: -10px;
    }

    .detail-main-wrapper {
        gap: 72px;
    }

    .mainpage-products-list {
        gap: 30px 20px;
    }

    .mainpage-products-list .product .product-price:after {
        bottom: -8px;
    }

    .mainpage-products-list .product:first-child .product-price:after {
        bottom: -24px;
    }
}