// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1279px) {
    :root {
        --rowGap: 25px;
        --fontSize: 13px;
        --lineHeight: 1.4;
    }

    .detail-omnibus {
        align-self: flex-start;
        margin-top: 0;
        font-size: 11px;
    }

    .footer-bottom-bar {
        padding: 30px 0 100px;
    }

    #container {
        overflow: clip;
    }

    .detail-omnibus {
        text-align: left;
    }

    main {
        padding-bottom: var(--rowGap) !important;
    }

    main.aside {
        grid-template-columns: minmax(0, 1fr);
        grid-gap: var(--rowGap) 0;
    }

    .inner {
        width: 100%;
        padding: 0 10px;
    }

    * {
        -webkit-tap-highlight-color: rgba(189, 195, 199, .75);
        -webkit-text-size-adjust: none;
    }

    body.privacy-box-visible {
        overflow: hidden;
        
        #container {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    body:has(.left-side-cookies) {

        .privacy-box-trigger {
            bottom: 15px;
            width: 35px;
            height: 35px;

            &.right-side-cookies {
                right: 15px;
            }

            &.left-side-cookies {
                left: 15px;
            }
        }
    }

    .privacy-box {
        bottom: auto;
        right: auto;
        left: 15px !important;
        right: 15px !important;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100vw;
        max-height: ~"calc(100% - 40px)";
    }

    input[type=text],
    input[type=password],
    input[type=email],
    select,
    textarea {
        font-size: 16px;
        padding: 0 14px;
    }

    textarea {
        padding: 10px 14px;
    }

    #facebook-box,
    .detail-loupe {
        display: none;
    }

    #detail-images {

        .swiper-button-prev,
        .swiper-button-next {
            height: 80px;
            margin-top: -40px !important;
        }

        .swiper-button-prev {
            left: -45px;
        }

        .swiper-button-next {
            right: -45px;
        }
    }

    .headline {
        font-size: 20px !important;
        margin-bottom: 16px;
    }

    #content {
        width: 100%;
        clear: both;
    }

    .sidebar {
        width: auto;
        display: contents;

        .sidebar-tabs {
            display: none;
        }
    }

    // SEARCH ENGINE
    #search-engine {
        display: none;
    }

    .newsletter-bg {
        background-image: none !important;
    }

    .newsletter-input {
        width: 100%;
    }

    .footer-content {
        flex-direction: column;
        padding: 20px 0;
    }

    .footer-item {
        width: 100% !important;
    }

    body:has(.gs-filtr-container) .filter-trigger {
        display: flex;
    }

    .filter-trigger,
    .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
        height: 40px;
        align-items: center;
        justify-content: space-between;
        font-weight: normal;
        font-size: 13px;
        gap: 5px;
        padding: 0 25px;
        min-width: 0;
        font-weight: bold;
        color: @header-color;
        border-color: @header-color;

        &:before {
            display: none;
        }
    }

    .filter-trigger {
        font-size: 0px;
        gap: 4px;
        font-weight: 600;
        padding-left: 16px;

        &:before {
            .small-icon();
            content: "\e916";
            font-weight: 600;
        }

        &:after {
            content: 'Filtry i sortowanie';
            font-size: 13px;
        }
    }


    // SORTER 
    .dropdown-picker[data-dropdown-type="sort"] {
        display: none;
    }

    .dropdown-picker:not([data-dropdown-type="sort"]) {

        &:before {
            display: block;
            color: @font-color;
            font-weight: normal;
            left: 17px;
        }

        .dropdown-button {
            min-width: 160px;
            border-color: @border-color;
            font-weight: normal;

            &:after {
                color: @font-color;
                font-weight: normal;
            }
        }

        .dropdown-listing {

            label {
                padding: 5px 25px;
            }
        }
    }

    // FILTER
    #filter {
        display: none;
    }

    .product-images-mini {
        width: 100%;
        height: auto;
        margin-top: 10px;

        .swiper-wrapper {
            transform: none !important;
            width: auto;
            justify-content: center;
        }

        .swiper-slide {
            width: 10px;
            height: 10px;
            background-color: @border-color;
            border-radius: 50%;
            margin: 0 5px;
        }

        img {
            display: none !important;
        }
    }

    .user-custom-content p {
        margin-bottom: 10px;
    }

    // OTHER
    hr {
        margin: 20px 0;
    }

    .overlay-visible {
        overflow: hidden;
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        top: 0;
    }

    main:not(.aside) #tree {
        display: block;
    }

    .tree-canvas {
        position: fixed;
        background: rgb(0, 0, 0) none repeat scroll 0% 0%;
        will-change: opacity;
        transition: opacity 300ms ease 0s;
        inset: 0px;
        z-index: 1001;
        pointer-events: none;
        opacity: 0;
    }

    #tree {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate3d(-100%, 0, 0);
        overflow: hidden;
    }


    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    :root {
        --prodGap: 20px;
        --prodGapSlider: 15px;
        --prodGapVertical: 30px;
        --rowGap: 48px;
        --iconSize: 20px;
    }

    // 1279 HEADER
    .top-bar {
        padding: 4px;
        font-size: 12px;
    }

    .shop-header-container {
        padding: 8px 0;
    }

    #container {
        overflow: clip;
    }

    .shop-header {
        padding-bottom: 0;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: white;
        .shadow();
    }

    .mainmenu {
        display: none;
    }

    .tree-canvas {
        display: block;
    }

    .logo {
        height: 38px;
    }

    .header-tools-wrapper {
        gap: 24px;
    }

    #cart-box,
    #wishlist-box,
    .log-button,
    .search-engine-trigger,
    .tree-trigger {
        padding-top: 28px;

        &:before {
            font-size: 22px;
        }

        em {
            letter-spacing: 0.1em;
            font-size: 9px;
            font-weight: 600;
            display: block;
            color: @header-color;
        }
    }

    .search-engine-trigger {

        &:before {
            content: "\e919";
        }
    }

    .hamburger-icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 22px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        justify-content: center;
        align-items: center;

        span {
            display: block;
            width: 80%;
            height: 1px;
            border-radius: 50%;
            background-color: @header-color;
        }
    }

    #cart-box i {
        right: 4px !important;
    }

    #wishlist-box:after {
        right: 14px;
    }

    // 1279 BASE
    .product:after {
        display: none !important;
    }

    .product {
        gap: 2px;
        padding-bottom: 41px;
    }

    .product-price {
        gap: 10px;
        font-size: 14px;

        del {
            font-size: 13px;
            position: relative;
            top: 1px;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 24px;
        height: 24px;
        margin-top: -12px;
    }

    .product-image {
        position: static;
    }

    .product-hover {
        opacity: 1;
        visibility: visible;
        background: none;

        a.product-info {
            display: none;
        }
    }

    a.product-name {

        &:after {
            content: '';
            position: absolute;
            inset: 0;
        }
    }

    .product-hover-buttons {
        top: initial;
        bottom: 1px;
        left: 0;
        transform: none;
        width: 100%;
        gap: 4px;
        z-index: 0;

        button {
            border: 1px solid @header-color;
            color: @header-color;
            height: 32px;
            width: 32px;
            z-index: 3;

            &:before {
                color: inherit;
                font-size: 20px;
            }
        }

        .product-add {
            flex: 1;
            display: flex;
            gap: 6px;
            align-items: center;
            text-transform: uppercase;
            justify-content: center;
            letter-spacing: 0.01em;

            &:after {
                content: 'Kup produkt';
                white-space: nowrap;
                font-size: 13px;
            }

            &.disabled {
                display: flex !important;
                border: 1px solid darken(@border-color, 25%);
                color: darken(@border-color, 25%);
            }
        }
    }

    // 1279 MAINPAGE
    .promo-banners-wrapper {
        margin-top: -8px;
        gap: 16px;
    }
    
    .promo-banner {
        padding: 16px;

        figure {
            width: 36px;
            margin-bottom: 6px;
        }
    }

    .slider-header {
        margin-bottom: 16px;
        gap: 24px;

        .headline {
            margin-bottom: 0px !important;
        }
    }

    .fancy-button {
        margin: 16px auto 0 auto;
    }

    .mainpage-products-list {
        gap: 30px 20px;
        
        .product-price:after {
            display: none !important;
        }
    }

    .mainpage-products-list .product:first-child {

        .product-image {
            margin-bottom: 12px;
        }

        .product-name {
            font-size: 18px;
            margin-bottom: 4px;
        }

        .product-price {
            font-size: 18px;

            del {
                font-size: 16px;
            }
        }

        .product-hover-buttons {
            bottom: 104px;
        }
    }

    .mainpage-greeting .headline {
        margin-bottom: 14px;
    }

    // 1279 FOOTER
    .footer-content {
        flex-direction: column;
    }

    .footer-item > strong {
        margin-bottom: 6px;
    }

    .footer-item.submenu ul {
        columns: 1;
    }

    .footer-item.contact .contact-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;

        a.phone {
            padding-bottom: 5px;
        }
    }

    .footer-bottom-bar {
        padding-block: 20px 67px;
        flex-direction: column;
        gap: 16px;
    }

    // 1279 LISTING
    #breadcrumb {
        padding: 10px 0;
        margin-bottom: -24px;
    }

    .text-expand-wrapper {

        &:has(.expandable) {
            padding-bottom: 48px;
        }

    }

    .navigation-wrapper {
        gap: 16px;
    }

    .listing-wrapper {
        gap: 20px;
    }

    #pills-container:not(:empty) {
        margin-bottom: 8px !important;
    }

    .category-headline {
        margin-bottom: 0 !important;
    }

    body[data-canvas-filter] {

        &:has(#filter .gs-filtr-container) {

            .filter-trigger {
                display: flex;
            }
        }
    }

    // 1279 DETAIL
    .detail-main-wrapper {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
    }

    #detail-images {
        align-items: center;
        flex-direction: column;
        gap: 20px;
        height: auto;
        left: auto;
        margin: 0 auto;
        position: relative;
        top: auto;
        width: 100%;

        .swiper-button-prev {
            left: 10px;
            height: 48px;
        }

        .swiper-button-next {
            right: 10px;
            height: 48px;
        }
    }

    .product-images-mini {
        order: initial;
        height: auto;
        width: 100%;
    }

    .detail-headline {
        margin-bottom: 0 !important;
        text-align: center;
    }

    .detail-products-wrapper strong.headline {
        margin-bottom: 16px;
    }

    #detail-info {
        max-width: none;

        .detail-pricing,
        .detail-omnibus {
            align-self: center;
            margin-bottom: 10px;
        }
    }

    .product-images-mini .size-custom {
        padding: 0 !important;
    }

    .detail-options {
        padding-top: 10px;
    }

    .detail-adding {
        margin-bottom: 20px;
    }

    #detail-description,
    #detail-traits {
        margin-top: 28px;
    }

    .detail-ask-button, .detail-wishlist {
        padding-left: 32px;
    }

    #detail-traits .trait {
        gap: 24px;

        b {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
        }

        .trait-desc {
            flex: 1;
        }
    }

    .detail-comments-header {
        margin-bottom: 16px;

        .headline {
            margin-bottom: 0;
        }
    }

    .comments-list {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .comment > cite {
        padding: 20px;
        margin-bottom: 12px;
    }

    .comment-response {
        margin-block: 12px 6px;
        padding: 20px;
    }

    .under-comment {

        .added-date {
            padding-left: 12px;
            margin-left: 0;
        }

        .purchase-confirmed {
            gap: 6px;
        }
    }

    // iOS 12 fixes
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        .header-tools-wrapper {

            > * {
                margin-left: 10px;
            }
        }

        .hamburger-icon {
            .grid();
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 6px);
            gap: 0;
            justify-items: center;
            align-items: center;
        }

        .tree-canvas {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}